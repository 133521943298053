import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatDate, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdCheck, MdOutlineCheck, MdOutlineCheckCircle, MdOutlineCreateNewFolder, MdPersonAdd } from "react-icons/md";
import MainSummaryGraph from '../ones/MainSummaryGraph';
import { BiTimeFive } from "react-icons/bi";

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ allNotifications, setAllNotifications ] = useState(null);
  
  async function init () {
    //intialize this view here
    await getAllNotifications();
    setReady(true);
  }

  async function getAllNotifications() {
    await callApi('get_all_user_notifications.php', { }).then(response => {
      if(response.status === 1) {
        setAllNotifications(response.data);
      }
    })
  }

  useEffect(() => {
    init();
  }, [])


  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Notifications</h1>
          </div>

          

          {
            (allNotifications && allNotifications.length > 0) ?
            allNotifications.map((item, i) => {
              return (
                <div key={i} className="col-12 col-md-12">
                  <div 
                    className="card"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if(item.navItem) {
                        appContext.navTo({
                          item: item.navItem,
                          subItem: item.navSubItem,
                          extraItem: item.navExtraItem
                        })
                      }
                    }}
                  >
                    <div className="card-body">
                      <h6 
                        style={{ 
                          fontSize: "18px",
                          flexShrink: "0",
                        }} 
                        className={(item.status === 'read') ? "font-regular text-muted" : "font-regular text-primary"}
                      >
                        {item.message}
                      </h6>

                      <div className="text-end" style={{ marginTop: "30px" }}>
                        <h6 
                          style={{ 
                            fontSize: "14px",
                            flexShrink: "0",
                          }} 
                          className="font-regular text-end text-muted"
                        >
                          {
                            (item.status === 'read') ?
                            <MdOutlineCheckCircle size={20} style={{ marginRight: "20px" }} className="text-secondary"/>
                            :""
                          }
                          <BiTimeFive size={16}/> {formatDate(item.noteDate)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            :
            <div className="col-md-12">
              <h6 style={{ fontSize: "14px" }}>No notifications were found</h6>
            </div>
          }

        </div>

      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
