import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdAddCard, MdInfo, MdLogout, MdOutlineCreateNewFolder, MdPersonAdd, MdPersonOutline, MdPlaylistAdd } from "react-icons/md";
import MainSummaryGraph from '../ones/MainSummaryGraph';
import { AiOutlineProfile } from "react-icons/ai";
import { BsPersonCircle } from "react-icons/bs";

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  
  
  async function init () {
    //intialize this view here
    setReady(true);
  }

  function logOut() {
    appContext.activateDialog({
      message: "Do you want to logout?",
      onConfirm: async () => {
        appContext.setShowLoader(true);
        await callApi("logout.php", { }).then(async () => {
          await appContext.deleteLocalUsers().then(() => {
            window.location.reload();
          })
        })
      }
    })
  }

  useEffect(() => {
    init();
  }, [])


  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Settings</h1>
          </div>

          <div className="col-md-12">
            <div 
              className="bg-surface mShadow3 text-center"
              style={{
                width: "100%",
                padding: "30px",
                borderRadius: "15px",
              }}
            >
              <BsPersonCircle size={120} style={{ marginBottom: "30px" }}/>

              <h6 style={{ fontSize: "18px" }} className="font-bold">{appContext.userData.fullname}</h6>
              <h6 style={{ fontSize: "20px" }}>{appContext.userData.phone}</h6>
              <h6 style={{ fontSize: "24px" }} className="font-light">{appContext.userData.role}</h6>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">

              <div className="col-md-3 col-6">
                <button
                  className="btn btn-outline-dark btn-block btn-padded"
                  style={{
                    minHeight: "120px"
                  }}
                  onClick={() => {
                    logOut()
                  }}
                >
                  <MdLogout size={34}/> <br/> Log Out
                </button>
              </div>

              {
                (appContext.userData.role === 'admin') ?
                <>
                  <div className="col-md-3 col-6">
                    <button
                      className="btn btn-outline-dark btn-block btn-padded"
                      style={{
                        minHeight: "120px"
                      }}
                      onClick={() => {
                        appContext.navTo({
                          item: 'view',
                          subItem: 'create-diagnostic-test-type'
                        })
                      }}
                    >
                      <MdPlaylistAdd size={34}/> <br/> Add Examination Type
                    </button>
                  </div>

                  <div className="col-md-3 col-6">
                    <button
                      className="btn btn-outline-dark btn-block btn-padded"
                      style={{
                        minHeight: "120px"
                      }}
                      onClick={() => {
                        appContext.navTo({
                          item: 'view',
                          subItem: 'manage-clinic-info'
                        })
                      }}
                    >
                      <MdInfo size={34}/> <br/> Clinic Information
                    </button>
                  </div>
                </>
                :""
              }

            </div>
          </div>

        </div>

      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
