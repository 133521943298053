import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdMenu, MdOutlineCreateNewFolder, MdPhone } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { formatDate } from "../Helpers";
import { BsThreeDotsVertical } from 'react-icons/bs';

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ data, setData ] = useState(props.data);
  
  async function init () {
    //initialize this view here
    setReady(true);
  }

  function viewPatientInfo() {
    appContext.navTo({
      item: 'view',
      subItem: 'patient-info',
      extraItem: data.uid
    })
  }

  function viewTestResults() {
    appContext.navTo({
      item: 'view',
      subItem: 'test-results',
      extraItem: data.uid
    })
  }

  function editPatientInfo() {
    appContext.navTo({
      item: 'view',
      subItem: 'edit-patient-info',
      extraItem: data.uid
    })
  }

  function editTestResults() {
    appContext.navTo({
      item: 'view',
      subItem: 'edit-test-results',
      extraItem: data.uid
    })
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ]);

  //return render
  if(ready) {
    return (
      <div className="OnePatient">

        <h6
          style={{
            fontSize: "20px",
          }}
          className="font-regular mOneLineText"
        >
          {data.firstname} - {data.lastname}
        </h6>

        <h6
          style={{
            fontSize: "14px",
          }}
          className="font-regular mOneLineText"
        >
          <MdPhone size={20} style={{ marginRight: "10px" }}/>
          {data.phone}
        </h6>

        <h6
          style={{
            fontSize: "16px",
            marginTop: "20px",
          }}
          className="font-semi-bold text-primary"
        >
          {data.gender}
        </h6>

        <h6
          style={{
            fontSize: "12px",
            color: (data.status === 'tested') ? "var(--primaryColor)" : "var(--secondaryColor)"
          }}
          className="font-semi-bold"
        >
          {data.status}
        </h6>

        <h6
          style={{
            fontSize: "14px",
            borderLeft: "6px solid var(--primaryColor)",
            paddingLeft: "5px",
            marginLeft: "0px",
            marginTop: "20px",
          }}
          className="font-semi-bold text-muted"
        >
          {data.uid}
        </h6>
        

        
        
        <div className="d-flex justify-content-end" style={{ marginTop: "30px" }}>
          <h6
            style={{
              fontSize: "14px",
              width: "auto",
              margin: "0",
              padding: "0"
            }}
            className="font-regular mOneLineText mNoMargin mNoPadding align-self-center"
          >
            <BiTimeFive size={20} style={{ marginRight: "2px" }}/>
            {formatDate(data.visitDate)}
          </h6>

          
          <div className="dropdown align-self-center mNoMargin mNoPadding">
            
            <BsThreeDotsVertical
              size={24}
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              style={{
                margin: "0",
                marginLeft: "20px",
                padding: "0",
                cursor: "pointer",
              }}
            />
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" onClick={viewPatientInfo}>View More Info</a></li>
              <li><a className="dropdown-item" onClick={viewTestResults}>View Examination Report</a></li>
              {
                (data.status === 'untested' && (appContext.userData.role === 'admin' || appContext.userData.role === 'sonographer')) ?
                <>
                  <li><a className="dropdown-item" onClick={editTestResults}>Create Examination Report</a></li>
                </>
                :""
              }

              {
                (data.status === 'tested' && (appContext.userData.role === 'admin' || appContext.userData.role === 'sonographer')) ?
                <>
                  <li><a className="dropdown-item" onClick={editTestResults}>Edit Examination Report</a></li>
                </>
                :""
              }
            </ul>
          </div>
          
        </div>
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
