import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, formatDate, getInlineLoader } from "../Helpers";
import { MdAttachFile, MdCheck, MdCheckCircleOutline, MdClose, MdOutlineCreateNewFolder, MdOutlineFrontHand } from "react-icons/md";
import OnePatient from '../ones/OnePatient';
import { BiTimeFive } from "react-icons/bi";
import { decodeAge } from "../Helpers";

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ revisits, setRevisits ] = useState(null)
  const [ initialVisitData, setInitialVisitData ] = useState(null);
  const [ testResults, setTestResults ] = useState(null);

  
  async function init () {
    //intialize this view here
    await getData();
    setReady(true);
  }

  async function getData() {
    await callApi('get_patient_data.php', { id }).then(response => {
      if(response.status === 1) {
        setData(response.data);
        setRevisits(response.revisits);
        setInitialVisitData(response.initialVisitData);
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  function handleTestRemarksChange(testIndex, _remarks) {
    setTestResults((oldTests) => {
      let newTests = [ ...oldTests ];
      newTests[testIndex].testRemarks = _remarks;

      return newTests;
    })
  }

  function handleTestResultsChange(testIndex, _results) {
    setTestResults((oldTests) => {
      let newTests = [ ...oldTests ];
      newTests[testIndex].testResults = _results;

      return newTests;
    })
  }

  async function saveReport() {
    setLoading(true);
    await callApi('save_test_results.php', { id, testResults: JSON.stringify(testResults) }).then(response => {
      if(response.status === 1) {
        appContext.getPatientsSummary();
        appContext.tellMessage("Examinations Report was saved!");
        appContext.navTo({
          item: "view",
          subItem: "test-results",
          extraItem: id,
        })
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }


  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    if(data) {
      setTestResults(JSON.parse(data.testResults));
    }
  }, [ data ])

  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])

  //return render
  if(ready && data) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader font-bold">{data.firstname} - {data.lastname}</h1>
            <h6
              style={{
                fontSize: "24px"
              }}
              className="text-secondary font-light"
            >
              {data.uid}
            </h6>
            <h6
              style={{
                fontSize: "16px"
              }}
              className="text-primary font-semi-bold"
            >
              {data.status}
            </h6>
            

            <h6
              style={{
                fontSize: "16px",
                marginTop: "20px",
              }}
              className="font-light"
            >
              Received By: <span className="font-semi-bold">{(data.receiverData) ? data.receiverData.fullname : "N/A"}</span>
            </h6>
            <h6
              style={{
                fontSize: "16px",
                marginTop: "0px",
              }}
              className="font-light"
            >
              Examined By: <span className="font-semi-bold">{(data.testerData) ? data.testerData.fullname : "N/A"}</span>
            </h6>

            <h6
              style={{
                fontSize: "14px",
                width: "auto",
                margin: "0",
                padding: "0",
                marginTop: "20px",
              }}
              className="font-regular"
            >
              <BiTimeFive size={20} style={{ marginRight: "2px" }}/>
              {formatDate(data.visitDate)}
            </h6>

            <hr/>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{data.firstname || "N/A"}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>First name</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{data.lastname || "N/A"}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Last name</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{formatDate(data.visitDate) || "N/A"}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Date of Visit</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{formatDate(data.birthDate).substring(0,10) || "N/A"}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Date of Birth</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{decodeAge(data.age) || "N/A"}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Age (at time of visit)</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{data.gender || "N/A"}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Gender</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{data.phone || "N/A"}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Phone</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{data.initialVisitId || data.uid}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Patient ID</h5>
              </div>
            </div>
          </div>
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Examinations</h1>
            {
              (testResults && testResults.length > 0) ?
              <div className="row">
                {
                  testResults.map((item, i) => {
                    return (
                      <div
                        className="col-12"
                        key={i}
                      >
                        <div className="card">
                          <div className="card-body">
                            <h1 style={{ fontSize: "20px" }} className="font-bold text-secondary">{item.testTypeName}</h1>

                            <div className="form-group">
                              <label>Results/Finding</label>
                              <textarea
                                className="form-control"
                                rows={4}
                                value={item.testResults}
                                onChange={(e) => {
                                  handleTestResultsChange(i, e.target.value)
                                }}
                              >

                              </textarea>
                            </div>

                            <div className="form-group">
                              <label>Remarks</label>
                              <textarea
                                className="form-control"
                                rows={4}
                                value={item.testRemarks}
                                onChange={(e) => {
                                  handleTestRemarksChange(i, e.target.value)
                                }}
                              >

                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

                <div
                  className="col-12 text-end"
                >
                  <button
                    className="btn bg-primary btn-padded"
                    onClick={() => {
                      appContext.activateDialog({
                        message: "Changes to this examinations report will be save, continue?",
                        onConfirm: saveReport,
                      })
                    }}
                  >
                    Save Report
                  </button>
                </div>
              </div>
              :
              <h6 className="font-light">No examinations</h6>
            }
            
          </div>

          

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
