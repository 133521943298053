import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { MdSettings, MdArrowBack, MdDarkMode, MdOutlineDarkMode, MdNotifications } from 'react-icons/md';
import { Modal } from 'bootstrap';
import TopBar from '../components/TopBar';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ view, setView ] = useState(<></>);
  const [ modal, setModal ] = useState(null);


  const showModal = () => {
    modal.show();
  }

  const hideModal = () => {
    modal.hide();
    setView(null);
  }

  const init = () => {
    if(appContext.showViewer) {
      //launch the modal
      showModal();
      //then get content to view
      setView(appContext.viewerView);
    } else {
      //hide modal
      hideModal();
      //reset view
      setView(<></>);
    }
  }

  useEffect(() => {
    setModal(new Modal(document.getElementById('viewerModal')))
  }, [ ]);

  useEffect(() => {
    if(modal) {
      init();
      //set listeners
      let mainHidder = () => {
        appContext.setShowViewer(false)
        setView(<></>);
      }
      document.getElementById('viewerModal').addEventListener('hidden.bs.modal', mainHidder);

      return (() => {
        document.getElementById('viewerModal').removeEventListener('hidden.bs.modal', mainHidder);
      })
    } else {
      setModal(new Modal(document.getElementById('viewerModal')))
    }
  }, [ modal ])

  useEffect(() => {
    if(appContext.showViewer) {
      //...
      if(modal) {
        init();
      } else {
        setModal(new Modal(document.getElementById('viewerModal')))
      }
      //...
    } else {
      if(modal) {
        hideModal();
      }
    }
  }, [  appContext.showViewer]);

  

  useEffect(() => {
    if(appContext.showViewer) {
      setView(appContext.viewerView);
    }
  }, [ appContext.showViewer, appContext.viewerView ])
  return (
    <div className="modal fade" data-bs-backdrop="static" id="viewerModal"  aria-labelledby="viewerModalLabel" aria-hidden="true">
      <div className="modal-fullscreen modal-dialog">
        <div className="modal-content">

          <div className="modal-body" style={{ padding:"0" }}>
            <TopBar isViewer={true}/>
            <div style={{ width: "100%", height: "auto", paddingTop: "var(--topBarHeight)", paddingBottom: "var(--topBarHeight)" }}>
              {view}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
