import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { MdArrowBack } from 'react-icons/md';
import { TbMenu, TbLogin } from 'react-icons/tb';
import { AiOutlineMenu, AiOutlineArrowLeft, AiOutlineClose, AiOutlineBackward } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { callApi } from "../Helpers";

export default function TopBar (props) {

  const appContext = useContext(AppContext);

  const [ title, setTitle ] = useState(props.title);
  const [ isViewer, setIsViewer ] = useState(props.isViewer);

  function toggleSideBar() {
    appContext.setIsSideBarClosed(prev => !prev);
  }

  function logOut() {
    appContext.activateDialog({
      message: "Do you want to logout?",
      onConfirm: async () => {
        appContext.setShowLoader(true);
        await callApi("logout.php", { }).then(async () => {
          await appContext.deleteLocalUsers().then(() => {
            window.location.reload();
          })
        })
      }
    })
  }

  useEffect(() => {
    setTitle(props.title);
    setIsViewer(props.isViewer)
  }, [ props.isViewer, props.title ])

  if(appContext.auth) {
    if(isViewer) {
      return (
        <div className="TopBar" data-is-viewer={true}>
          <div className="container d-flex" >
            <MdArrowBack
              size={30} 
              style={{ flexShrink: "0", cursor: "pointer" }} 
              className="mNoMargin mNoPadding align-self-center"
              onClick={() => appContext.navBack()}
            />
            &nbsp;&nbsp;
            <h5 
              className="align-self-center mNoMargin mNoPadding" 
              style={{ flexGrow: "1", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {title}
            </h5>
            
          </div>
        </div>
      );
      
    } else {
      return (
        <div className="TopBar">
          <div className="container d-flex" >
            <AiOutlineMenu
              size={30} 
              style={{ flexShrink: "0", cursor: "pointer" }} 
              className="mNoMargin mNoPadding align-self-center mobileOnly"
              onClick={() => appContext.setIsSideBarClosed(!appContext.isSideBarClosed)}
            />
            &nbsp;&nbsp;
            <h5 
              className="align-self-center font-semi-bold mNoMargin mNoPadding" 
              style={{ flexGrow: "1", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: "20px" }}>
              Dr. MSUA Clinic.
            </h5>
            
            <MdLogout
              size={30} 
              style={{ flexShrink: "0", cursor: "pointer" }} 
              className="mNoMargin mNoPadding align-self-center"
              onClick={logOut}
            />
          </div>
        </div>
      );
    }
  }
}