import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import MainSummaryGraph from '../ones/MainSummaryGraph';

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  

  
  async function init () {
    //intialize this view here
    await appContext.getPatientsSummary();
    await appContext.getDiagnosticTestTypes();
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])


  //return render
  if(ready && appContext.patientsSummary) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Patients</h1>
          </div>

          <div className="col-md-12 mNoMargin">
            <MainSummaryGraph/>
          </div>

          <div className="col-md-12">
            <div className="dropdown">
              <h6 className="font-bold text-muted dropdown-toggle" data-bs-toggle="dropdown" style={{ marginTop: "40px", fontSize: "14px", width: "auto" }}>
                {(appContext.period === 'today') ? "Today" : ""}
                {(appContext.period === 'week') ? "This Week" : ""}
                {(appContext.period === 'alltime') ? "All Time" : ""}
              </h6>
              
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" onClick={() => appContext.setPeriod('today')}>Today</a></li>
                <li><a className="dropdown-item" onClick={() => appContext.setPeriod('week')}>This Week</a></li>
                <li><a className="dropdown-item" onClick={() => appContext.setPeriod('alltime')}>All Time</a></li>
              </ul>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div 
              className="card bg-surface"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'patients',
                  subItem: 'all',
                  extraItem: appContext.period,
                })
              }}
            >
              <div className="card-body">
                <h6 style={{ fontSize: "28px" }}>{appContext.patientsSummary[appContext.period].allPatients}</h6>
                <h6 style={{ fontSize: "12px" }} className="font-semi-bold">All</h6>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div 
              className="card bg-surface"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'patients',
                  subItem: 'untested',
                  extraItem: appContext.period,
                })
              }}
            >
              <div className="card-body">
                <h6 style={{ fontSize: "28px" }}>{appContext.patientsSummary[appContext.period].untestedPatients}</h6>
                <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Untested</h6>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div 
              className="card  bg-surface"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'patients',
                  subItem: 'tested',
                  extraItem: appContext.period,
                })
              }}
            >
              <div className="card-body">
                <h6 style={{ fontSize: "28px" }}>{appContext.patientsSummary[appContext.period].testedPatients}</h6>
                <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Tested</h6>
              </div>
            </div>
          </div>

          
          <div className="col-6 col-md-3">
            <div 
              className="card bg-surface"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'view',
                  subItem: 'register-patient',
                })
              }}
            >
              <div className="card-body">
                <h6 style={{ fontSize: "28px" }}><MdOutlineCreateNewFolder/></h6>
                <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Register New</h6>
              </div>
            </div>
          </div>
          

        </div>

        <div className="row">
          
          {/*
          <div className="col-md-12 text-end" style={{ paddingTop: "30px" }}>
            <button
              style={{
              }}
              className="btn btn-outline-dark btn-sm btn-padded"
              onClick={() => {
                appContext.navTo({
                  item: 'view',
                  subItem: 'create-diagnostic-test-type',
                })
              }}
            >
              Add New Examination
            </button>
          </div>
          */}

          {
            (appContext.diagnosticTestTypes && appContext.diagnosticTestTypes.length > 0) ?
            appContext.diagnosticTestTypes.map((item, i) => {
              return (
                <div key={i} className="col-12 col-md-6">
                  <div 
                    className="card"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'patients',
                        subItem: 'all',
                        extraItem: appContext.period,
                        moreItem: item.uid,
                      })
                    }}
                  >
                    <div className="card-body d-flex">
                      <h6 
                        style={{ 
                          fontSize: "24px",
                          width: "70%",
                          flexShrink: "0",
                        }} 
                        className="align-self-center mNoMargin mNoPadding text-primary font-regular"
                      >
                        {item.title}
                      </h6>

                      <h6 
                        style={{ 
                          fontSize: "40px",
                          width: "30%",
                          flexShrink: "0",
                        }} 
                        className="align-self-center mNoMargin mNoPadding font-light text-end text-secondary"
                      >
                        {formatMoneyShorten(appContext.patientsSummary[appContext.period].testTypesSummary[item.uid].total)}
                      </h6>

                    </div>
                  </div>
                </div>
              )
            })
            :
            <div className="col-md-12">
              <h6 style={{ fontSize: "14px" }}>No examination types were found</h6>
            </div>
          }
          

        </div>


      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
