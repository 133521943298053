import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdArrowBack, MdArrowBackIos, MdArrowForwardIos, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineCreateNewFolder, MdSearch } from "react-icons/md";
import { TbArrowForward } from "react-icons/tb";
import { BsForward } from "react-icons/bs";
import OnePatient from '../ones/OnePatient';

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ type, setType ] = useState(props.type || 'all'); //all,  tested, untested
  const [ period, setPeriod ] = useState(props.period || 'today'); //today, week, alltime
  const [ diagnosticTestType, setDiagnosticTestType ] = useState(props.diagnosticTestType); //specified diagnostic type
  const [ filter, setFilter ] = useState("");
  const [ feed, setFeed ] = useState(null);
  
  async function init () {
    //intialize this view here
    await appContext.getDiagnosticTestTypes();
    await getFeed();
    setReady(true);
  }

  async function getFeed() {
    await callApi(
      'get_patients_feed.php', 
      { 
        type,
        period,
        diagnosticTestType: diagnosticTestType || "",
      }).then(response => {
      if(response.status === 1) {
        setFeed(response.data);
      } else {
        appContext.tellError("Network error, could not fetch projects")
      }
    })
  }

  function feedForward() {

  }

  function feedBackward() {
    getFeed();
  }

  useEffect(() => {
    init();
  }, [ type, period, diagnosticTestType ])

  useEffect(() => {
    setType(props.type || 'all');
    setPeriod(props.period || 'today');
    setDiagnosticTestType(props.diagnosticTestType);
  }, [ props.type, props.period, props.diagnosticTestType ])


  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">
              Patients
            </h1>

            <h6 className="font-bold text-muted" style={{ marginTop: "40px", fontSize: "14px" }}>
              {(type && type.trim().length > 0) ? <span style={{ textTransform: "uppercase" }}>{type}</span> : ""}
              {(period && period.trim().length > 0) ? <span style={{ textTransform: "uppercase" }}> <MdArrowForwardIos size={12}/> {period}</span> : ""}
              {
                (diagnosticTestType && diagnosticTestType.trim().length > 0) ?
                appContext.diagnosticTestTypes.map((item, i) => {
                  if(item.uid === diagnosticTestType) {
                    return (
                      <span style={{ textTransform: "uppercase" }} key={i}> <MdArrowForwardIos size={12}/> {item.title}</span>
                    )
                  }
                })
                :""
              }
            </h6>
          </div>

          <div className="col-md-12">
            <div
              style={{
                maxWidth: "400px",
              }}
              className="d-flex"
            >
              <MdSearch
                size={30}
                className="align-self-center mNoPadding mNoMargin"
                style={{
                  flexShrink: "0"
                }}
              />

              <input
                className="form-control align-self-center"
                placeholder="Filter here by names, id or phone"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="col-md-12">
            {
              (feed) ?
              (feed.length > 0) ?
              <>
                {
                  (filter && filter.trim().length > 0) ?
                  <div style={{ width: "100%" }}>
                    <h6 className="font-light" style={{ fontSize: "18px" }}>Results for {filter}</h6>
                  </div>
                  :
                  ""
                }

                {
                  feed.map((item, i) => {
                    if(filter && filter.trim().length > 0) {
                      if(
                        String(item.firstname).toLowerCase().indexOf(String(filter).toLowerCase()) >= 0 ||
                        String(item.lastname).toLowerCase().indexOf(String(filter).toLowerCase()) >= 0 ||
                        String(item.phone).toLowerCase().indexOf(String(filter).toLowerCase()) >= 0 ||
                        String(item.uid).toLowerCase().indexOf(String(filter).toLowerCase()) >= 0
                      ) {
                        return (
                          <OnePatient key={i} data={item}/>
                        )
                      } else {
                        //does not match filter
                      }
                    } else {
                      return (
                        <OnePatient key={i} data={item}/>
                      )
                    }
                    
                  })
                }
              </>
              :
              <div style={{ width: "100%", padding: "10px" }}>
                <h6 style={{ fontSize: "14px" }}>No patients were found</h6>
              </div>
              :
              <div style={{ width: "100%" }} className="mSupportLoading">
                {getInlineLoader()}
              </div>
            }
          </div>
          
        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
