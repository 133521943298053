import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import { callApi, getInlineLoader } from "../Helpers";
import logo from '../assets/logo.png'
import Viewer from '../components/Viewer';

export default function Login() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ phone, setPhone ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ loading, setLoading ] = useState(false);


  async function login() {
    if(!loading) {
      if(phone.trim().length > 0) {
        if(password.trim().length > 0) {

          setLoading(true);
          await callApi("login.php", { phone, password }).then(response => {
            if(response.status === 1) {
              appContext.saveLocalUser({ userId: response.userId, userToken: response.userToken });
              appContext.navTo({ item: 'patients' });
              appContext.refresh();
              appContext.tellMessage("Welcome back");
            } else {
              appContext.tellError(response.msg);
            }
          })
          setLoading(false);

        } else {
          appContext.tellError('Invalid password');
        }
      } else {
        appContext.tellError("Invalid phone number")
      }
     
    }
  }
  
  function init () {
    //intialize this view here
    setReady(true)
  }

  useEffect(() => {
    init()
  }, [ ])

  //return render
  if(ready) {
    return (
      <>
        <Viewer/>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                style={{
                  maxWidth: "350px",
                  margin: "0 auto",
                }}
              >
                <div 
                  className="form-group"
                  style={{ 
                    marginTop: "0", 
                    marginBottom: "calc(var(--topBarHeight) * 0.1)" 
                  }}
                >
                  <div className="text-center mobileOnly">
                    <img src={logo} style={{ width: "200px", marginBottom: "40px" }}/>
                  </div>
                  <h1  className="font-light">Patients Information System</h1>
                  <small className="form-text">Login Please</small>
                  <hr/>
                </div>

                <div className="form-group">
                  <label>Phone</label>
                  <input value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" type="text" maxLength={10} placeholder="0XXXXXXXXX"/>
                </div>

                <div className="form-group">
                  <label>Password</label>
                  <input value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" type="password"/>
                </div>
                <div className="form-group text-end">
                  <button onClick={login} className="mSupportLoading btn bg-primary btn-padded font-semi-bold">
                    {(loading) ? getInlineLoader() : "LOGIN"}
                  </button>
                </div>
                
              </div>
            </div>
            <div className="col-md-6" style={{ paddingTop: "40px" }}>
              
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
