import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, getInlineLoader } from "../Helpers";
import { MdOutlineCreateNewFolder } from "react-icons/md";

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ clinicName, setClinicName ] = useState(appContext.systemParams?.clinicName || "");
  const [ address, setAddress ] = useState(appContext.systemParams?.address || "");
  const [ phone, setPhone ] = useState(appContext.systemParams?.phone || "");

  
  async function init () {
    //intialize this view here
    await appContext.getSystemParams();
    setReady(true);
  }

  async function updateInfo() {
    if(!loading) {
      if(clinicName.trim().length > 0) {
        if(phone.trim().length > 0) {
          if(address.trim().length > 0) {
            setLoading(true);
            await callApi('update_clinic_info.php', { clinicName, address, phone }).then((response) => {
              if(response.status === 1) {
                appContext.tellMessage("Changes were saved");
                appContext.getSystemParams();
              } else {
                appContext.tellError(response.msg)
              }
            })
            setLoading(false);
          } else {
            appContext.tellError("Invalid Address");
          }
        } else {
          appContext.tellError("Invalid Phone");
        }
      } else {
        appContext.tellError("Invalid Clinic name");
      }
    } else {
      appContext.tellError("Please wait...");
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(appContext.systemParams) {
      setClinicName(appContext.systemParams.clinicName);
      setAddress(appContext.systemParams.address);
      setPhone(appContext.systemParams.phone);
    }
  }, [ appContext.systemParams ])

  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])

  
  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Manage Clinic Info</h1>
          </div>

          
          <div className="col-md-6">
            <div className="form-group">
              <label>Clinic Name<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={clinicName}
                onChange={(e) => {
                  setClinicName(e.target.value)
                }}
              />
            </div>

            <div className="form-group">
              <label>Phone<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value)
                }}
              />
            </div>

            <div className="form-group">
              <label>Address<span className="text-muted">*</span></label>
              <textarea
                className="form-control"
                rows={3}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value)
                }}
              ></textarea>
            </div>

            <div className="form-group text-end">
              <button
                className="btn btn-padded bg-primary mSupportLoading"
                onClick={() => {
                  if(!loading) {
                    appContext.activateDialog({
                      message: `Save Changes?`,
                      onConfirm: updateInfo,
                    })
                  }
                }}
              >
                {(loading) ? getInlineLoader() : "Save Changes" }
              </button>
            </div>
          </div>
          

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
