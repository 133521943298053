import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { MdDashboard, MdDarkMode, MdOutlineDarkMode } from 'react-icons/md';
import Login from '../views/Login';
import { getInlineLoader } from "../Helpers";


export default function MainBody (props) {

  const appContext = useContext(AppContext);

  if(appContext.auth) {
    if(appContext.userData) {
      return (
        <div className="MainBody">
          {appContext.mainView}
        </div>
      )
    } else {
      // just show loader
      return (
        <div className="MainBody">
          <div className="container mSupportLoading">
            {getInlineLoader()}
          </div>
        </div>
      )
    }
  } else {
    //show login panel
    return (
      <div className="MainBody">
        <div className="container">
          <Login/>
        </div>
      </div>
    )
  }
}