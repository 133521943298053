import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, getInlineLoader } from "../Helpers";
import NotificationsHome from '../views/NotificationsHome';

export default function Notifications() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ view, setView ] = useState(null);

  
  async function init () {
    //intialize this view here
    setReady(true);
  }

  function getView() {
    if(
      !appContext.navSubItem &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      return <NotificationsHome/>;
    } else {
      
    }
    
    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    let _view = getView();

    if(_view) {
      setView(_view);
    }
  }, [ appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem ])

  //return render
  if(ready) {
    return view;
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
