import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, getInlineLoader } from "../Helpers";
import { MdOutlineCreateNewFolder } from "react-icons/md";

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ title, setTitle ] = useState("");
  const [ description, setDescription ] = useState("");

  
  async function init () {
    //intialize this view here
    setReady(true);
  }

  async function create() {
    if(!loading) {
      if(title.trim().length > 0) {
        setLoading(true);
        await callApi('create_diagnostic_test_type.php', { title, description }).then((response) => {
          if(response.status === 1) {
            //successful
            appContext.tellMessage("Successful");
            appContext.getPatientsSummary();
            appContext.getDiagnosticTestTypes();
            setTitle("");
            setDescription("");
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
      } else {
        appContext.tellError("Invalid examination title");
      }
    } else {
      appContext.tellError("Please wait...");
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])

  
  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Create New Examination Type</h1>
          </div>

          
          <div className="col-md-6">
            <div className="form-group">
              <label>Title<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
              />
            </div>

            <div className="form-group text-end">
              <button
                className="btn btn-padded bg-primary mSupportLoading"
                onClick={() => {
                  if(!loading) {
                    appContext.activateDialog({
                      message: `Examination "${title}" will be created, continue?`,
                      onConfirm: create,
                    })
                  }
                }}
              >
                {(loading) ? getInlineLoader() : "Create" }
              </button>
            </div>
          </div>
          

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
