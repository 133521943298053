import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdOutlineCreateNewFolder, MdPersonAdd } from "react-icons/md";
import MainSummaryGraph from '../ones/MainSummaryGraph';

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  
  
  async function init () {
    //intialize this view here
    await appContext.getAllStaff();
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])


  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Staff</h1>
          </div>

          <div className="col-md-12 text-end" style={{ paddingTop: "30px" }}>
            <button
              style={{
              }}
              className="btn btn-outline-dark btn-sm btn-padded"
              onClick={() => {
                appContext.navTo({
                  item: 'view',
                  subItem: 'create-new-staff',
                })
              }}
            >
              <MdPersonAdd size={24}/> New Staff
            </button>
          </div>
          

          {
            (appContext.allStaff && appContext.allStaff.length > 0) ?
            appContext.allStaff.map((item, i) => {
              return (
                <div key={i} className="col-12 col-md-12">
                  <div 
                    className="card"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'view',
                        subItem: 'staff',
                        extraItem: item.id
                      })
                    }}
                  >
                    <div className="card-body">
                      <h6 
                        style={{ 
                          fontSize: "24px",
                          flexShrink: "0",
                        }} 
                        className="font-regular"
                      >
                        {item.fullname}
                      </h6>

                      <h6 
                        style={{ 
                          fontSize: "16px",
                          flexShrink: "0",
                        }} 
                        className="font-regular"
                      >
                        Phone: <span className="font-semi-bold">{item.phone}</span>
                      </h6>

                      <h6 
                        style={{ 
                          fontSize: "16px",
                          flexShrink: "0",
                        }} 
                        className="font-regular"
                      >
                        Role: <span className="font-semi-bold">{item.role}</span>
                      </h6>

                    </div>
                  </div>
                </div>
              )
            })
            :
            <div className="col-md-12">
              <h6 style={{ fontSize: "14px" }}>No staff was found</h6>
            </div>
          }

        </div>

      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
