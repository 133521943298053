import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, formatDate, getInlineLoader } from "../Helpers";
import { MdAttachFile, MdCheck, MdCheckCircleOutline, MdClose, MdOutlineCreateNewFolder, MdOutlineFrontHand } from "react-icons/md";
import OnePatient from '../ones/OnePatient';
import { BiTimeFive } from "react-icons/bi";

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  
  const [ newPassword, setNewPassword ] = useState("");
  const [ confirmPassword, setConfirmPassword ] = useState("");

  const [ fullname, setFullname ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ role, setRole ] = useState("");
  const [ roleDescription, setRoleDescription ] = useState("");
  
  async function init () {
    //intialize this view here
    await getData();
    setReady(true);
  }

  async function getData() {
    await callApi('get_staff_data.php', { id }).then(response => {
      if(response.status === 1) {
        setData(response.data);
        
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function updateDetails() {
    if(!loading) {
      //..
      if(fullname.trim().length > 0) {
        if(phone.trim().length === 10) {
          if(role.trim().length > 0) {
            setLoading(true);
            await callApi('update_staff_details.php', { id, fullname, phone, role, roleDescription }).then((response) => {
              if(response.status === 1) {
                //successful
                appContext.tellMessage("Successful");
                appContext.getAllStaff();
                appContext.getUserData();
                getData();
                
              } else {
                appContext.tellError(response.msg);
              }
            })
            setLoading(false);
          } else {
            appContext.tellError("Invalid Role");
          }
        } else {
          appContext.tellError("Invalid phone number");
        }
        
      } else {
        appContext.tellError("Invalid fullname");
      }
      //..
    } else {
      appContext.tellError("Please wait...");
    }
  }

  async function changePassword() {
    if(!loading) {
      if(newPassword.trim().length > 0) {
        if(newPassword === confirmPassword) {
          setLoading(true);
          await callApi('change_staff_password.php', { id, newPassword }).then(response => {
            if(response.status === 1) {
              appContext.tellMessage("Password was changed successfully");
              setNewPassword("");
              setConfirmPassword("");
            } else {
              appContext.tellError(response.msg);
            }
          })
          setLoading(false);
        } else {
          appContext.tellError("Please confirm correctly new password");
        }
      } else {
        appContext.tellError("Invalid password");
      }
    } else {
      appContext.tellError("Please wait");
    }
  }


  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    if(data) {
      setFullname(data.fullname);
      setPhone(data.phone);
      setRole(data.role);
      setRoleDescription(data.roleDescription);
    }
  }, [ data ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])


  //return render
  if(ready && data) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader font-bold">{data.fullname}</h1>
            <h6
              style={{
                fontSize: "24px"
              }}
              className="text-secondary font-light"
            >
              {data.phone}
            </h6>
            <h6
              style={{
                fontSize: "16px"
              }}
              className="text-primary font-semi-bold"
            >
              {data.role}
            </h6>
            

            <h6
              style={{
                fontSize: "16px",
                marginTop: "20px",
              }}
              className="font-light"
            >
              {data.roleDescription}
            </h6>
            
            
            <hr/>
          </div>

          <div className="col-md-6">
            <h1 className="mSectionHeader font-bold text-muted" style={{ fontSize: "20px" }}>Manage Details</h1>

            <div className="form-group">
              <label>Fullname<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={fullname}
                onChange={(e) => {
                  setFullname(e.target.value)
                }}
              />
            </div>

            <div className="form-group">
              <label>Phone<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={phone}
                placeholder="07XXXXXXXX or 06XXXXXXXX"
                onChange={(e) => {
                  setPhone(e.target.value)
                }}
                maxLength={10}
              />
            </div>

            <div className="form-group">
              <label>Role<span className="text-muted">*</span></label>
              <select
                className="form-control"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value)
                }}
              >
                <option value="receptionist">Receptionist</option>
                <option value="specialist">Specialist</option>
                <option value="admin">Admin</option>
              </select>
            </div>

            <div className="form-group">
              <label>Role Description</label>
              <input
                className="form-control"
                value={roleDescription}
                placeholder="Eg specialist radiologist"
                onChange={(e) => {
                  setRoleDescription(e.target.value)
                }}
              />
            </div>
            
            <div className="form-group text-end">
              <button
                className="btn btn-padded btn-danger"
                onClick={() => {
                  appContext.activateDialog({
                    message: "Save changes?",
                    onConfirm: updateDetails,
                  })
                }}
              >
                Update Details
              </button>
            </div>

          </div>
          
          <div className="col-md-6">
            <h1 className="mSectionHeader font-bold text-muted" style={{ fontSize: "20px" }}>Manage Password</h1>
            
            <div className="form-group">
              <label>New Password</label>
              <input
                className="form-control"
                type="password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </div>

            <div className="form-group">
              <label>Confirm New Password</label>
              <input
                className="form-control"
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </div>

            <div className="form-group text-end">
              <button
                className="btn btn-padded btn-danger"
                onClick={() => {
                  appContext.activateDialog({
                    message: "Password for this account will be changed, continue?",
                    onConfirm: changePassword,
                  })
                }}
              >
                Change Password
              </button>
            </div>

          </div>

          

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}



