import axios from "axios";
import { createContext, useContext } from "react";
import { ViewerContext } from "./App";
import Settings from "./main-views/Settings";
import Patients from "./main-views/Patients";
import Requests from "./main-views/Requests";
import Reports from "./main-views/Reports";
import Staff from "./main-views/Staff";
import CreateDiagnosticTestType from './views/CreateDiagnosticTestType'
import RegisterPatient from './views/RegisterPatient';
import ViewPatientInfo from './views/ViewPatientInfo';
import EditTestResults from './views/EditTestResults';
import ViewTestResults from './views/ViewTestResults';
import CreateNewStaff from './views/CreateNewStaff';
import ViewStaff from './views/ViewStaff';
import ManageClinicInfo from './views/ManageClinicInfo';
import Notifications from "./main-views/Notifications";

//export const BASE_API = "/github_projects/msua-medics/api/"; 
export const BASE_API = "/api/" ; 

export function getMainView(app) {
  if(!app.navItem || app.navItem === 'patients') {
    return <Patients/>;
  } else if(app.navItem === 'reports') {
    return <Reports/>;
  } else if(app.navItem === 'staff') {
    return <Staff/>;
  } else if(app.navItem === 'settings') {
    return <Settings/>;
  } else if(app.navItem === 'notifications') {
    return <Notifications/>;
  } 
}


export function getViewerView(app) {
  
  if(
    app.navSubItem === "create-diagnostic-test-type" && 
    !app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <CreateDiagnosticTestType/>;
  } else if(
    app.navSubItem === "register-patient" && 
    !app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <RegisterPatient/>;
  } else if(
    app.navSubItem === "patient-info" && 
    app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <ViewPatientInfo id={app.navExtraItem}/>;
  } else if(
    app.navSubItem === "staff" && 
    app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <ViewStaff id={app.navExtraItem}/>;
  } else if(
    app.navSubItem === "edit-test-results" && 
    app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <EditTestResults id={app.navExtraItem}/>;
  } else if(
    app.navSubItem === "test-results" && 
    app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <ViewTestResults id={app.navExtraItem}/>;
  } else if(
    app.navSubItem === "create-new-staff" && 
    !app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <CreateNewStaff/>;
  } else if(
    app.navSubItem === "manage-clinic-info" && 
    !app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <ManageClinicInfo/>;
  } 
  
}



export function formatDateForInput(timestamp) {
  const date = new Date(timestamp * 1000); // Convert PHP timestamp to JavaScript timestamp (milliseconds)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
  const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
  return `${year}-${month}-${day}`;
}

export function formatTanzanianPhoneNumber(phoneNumber) {
  // Remove any non-numeric characters from the input
  phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

  // Check if the number starts with "0" and has 9 digits
  if (phoneNumber.length === 10 && phoneNumber.charAt(0) === '0') {
    // Add the Tanzanian country code (255) to the number
    return '255' + phoneNumber.substring(1);
  } else if (phoneNumber.length === 12 && phoneNumber.substring(0, 3) === '255') {
    // The number already has the country code, return it as is
    return phoneNumber;
  } else {
    // Invalid or unsupported format
    return false;
  }
}

export function decodeAge(age) {
  const years = Math.floor(age);
  const months = Math.floor((age - years) * 12);
  
  if (years === 0) {
      return `${months} month${months !== 1 ? 's' : ''}`;
  }

  let result = `${years} year`;
  if (years !== 1) result += 's'; // pluralize "year" if necessary

  if (months > 0) {
      result += ` ${months} month`;
      if (months !== 1) result += 's'; // pluralize "month" if necessary
  }

  return result;
};

export function formatDate(timestamp, fromPhp = true) {
  timestamp = (fromPhp) ? Number(timestamp) * 1000 : timestamp;
  let date = new Date(timestamp);
  return (date.toLocaleString());
}

export function getInlineLoader() {
  return (
    <div className="Loader">
    </div>
  )
}

export function formatMoney(x) {
  //round x to 2 decimal places before og function
  x = Number(x);
  x = x.toFixed(0);

  //then continue
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}


export function formatMoneyShorten(num, digits = 2) {
  num = Number.parseFloat(num);
  if(num <= 1 && num >= -1 ) {
    return num;
  }

  const lookup = [
   { value: 1, symbol: "" },
   { value: 1e3, symbol: "K" },
   { value: 1e6, symbol: "M" },
   { value: 1e9, symbol: "B" },
   { value: 1e12, symbol: "T" },
   { value: 1e15, symbol: "P" },
   { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}



export function callApi (url, params) {
  return new Promise(async resolve => {
    await getLocalUser().then(user => {
      //..
      //call main api
      let formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }

      for (let key in user) {
        formData.append(key, user[key]);
      }

      axios.post(BASE_API+url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        
        if(process.env.REACT_APP_DEV_MODE) {
          //console.log(url);
          console.log(url, response.data);
        }

        resolve(response.data);
      }).catch(error => {
        resolve({
          status: 0,
          msg: "Network Connection Error",
        })
      })
      //..
    })
    
  })
}

const getLocalUser = () => {
  return new Promise(async resolve => {
    //use cookies
    let userId = getCookie('userId');
    let userToken = getCookie('userToken');
    resolve({
      userId,
      userToken,
    })

    
  })
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}




