import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { AiOutlineUser } from 'react-icons/ai'
import logo from '../assets/logo.png';
import { GrPlan } from 'react-icons/gr'
import { HiOutlineDocumentReport, HiOutlineUserGroup } from 'react-icons/hi'
import { TbLayoutDashboard } from 'react-icons/tb';
import { AiOutlineSchedule, AiOutlineSetting, AiOutlineClose } from 'react-icons/ai';
import { MdBusiness, MdNotifications, MdOutlineFrontHand, MdOutlineNotifications, MdOutlineSettings, MdPeople, MdPeopleOutline, MdSettings } from 'react-icons/md';
import { BsCardChecklist, BsPeople } from 'react-icons/bs';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { FaCodeBranch } from 'react-icons/fa';
import { BsInfoCircle } from 'react-icons/bs';
import { BiSupport } from 'react-icons/bi';

export default function SideBar (props) {

  const appContext = useContext(AppContext);

  const goTo = (id, subId = null) => {
    appContext.setIsSideBarClosed(true);
    appContext.navTo({
      item: id,
      subItem: subId,
    })
  }

  if(appContext.auth && appContext.userData) {
    return (
      <>
        <div className="SideBar" data-closed={appContext.isSideBarClosed}>
          <div className="mContainer">
            <div className="text-center" style={{ height: "calc(1.3 * var(--topBarHeight))", marginBottom: "40px", marginTop: "40px" }}>
              <img src={logo} style={{ height: "100%", flexShrink: "0" }} className="mNoMargin mNoPadding align-self-center"/>
            </div>

            {
              (appContext.userData.role === 'admin') ?
              <>
                <div onClick={() => goTo('patients')} className="mItem d-flex mBorder10" data-selected={(!appContext.navItem || appContext.navItem === 'patients')}>
                  <BsPeople className="mIcon"/>
                  <p className="mTitle">Patients</p>
                </div>

                {/*
                <div onClick={() => goTo('reports')} className="mItem d-flex mBorder10" data-selected={(appContext.navItem === 'reports')}>
                  <HiOutlineDocumentReport className="mIcon"/>
                  <p className="mTitle">Reports</p>
                </div>
                */}

                <div onClick={() => goTo('staff')} className="mItem d-flex mBorder10" data-selected={(appContext.navItem === 'staff')}>
                  <MdPeopleOutline className="mIcon"/>
                  <p className="mTitle">Staff</p>
                </div>

                <div onClick={() => goTo('settings')} className="mItem d-flex mBorder10" data-selected={(appContext.navItem === 'settings')}>
                  <MdOutlineSettings className="mIcon"/>
                  <p className="mTitle">Settings</p>
                </div>

                <div onClick={() => goTo('notifications')} className="mItem d-flex mBorder10" data-selected={(appContext.navItem === 'notifications')}>
                  <MdOutlineNotifications className="mIcon"/>
                  <p className="mTitle">Notifications</p>
                </div>

                <hr/>
    
              </>
              :""
            }

            {
              (appContext.userData.role === 'receptionist') ?
              <>
                <div onClick={() => goTo('patients')} className="mItem d-flex mBorder10" data-selected={(!appContext.navItem || appContext.navItem === 'patients')}>
                  <BsPeople className="mIcon"/>
                  <p className="mTitle">Patients</p>
                </div>

                <div onClick={() => goTo('settings')} className="mItem d-flex mBorder10" data-selected={(appContext.navItem === 'settings')}>
                  <MdOutlineSettings className="mIcon"/>
                  <p className="mTitle">Settings</p>
                </div>

                <div onClick={() => goTo('notifications')} className="mItem d-flex mBorder10" data-selected={(appContext.navItem === 'notifications')}>
                  <MdOutlineNotifications className="mIcon"/>
                  <p className="mTitle">Notifications</p>
                </div>

                <hr/>
    
              </>
              :""
            }

            {
              (appContext.userData.role === 'sonographer') ?
              <>
                <div onClick={() => goTo('patients')} className="mItem d-flex mBorder10" data-selected={(!appContext.navItem || appContext.navItem === 'patients')}>
                  <BsPeople className="mIcon"/>
                  <p className="mTitle">Patients</p>
                </div>

                <div onClick={() => goTo('settings')} className="mItem d-flex mBorder10" data-selected={(appContext.navItem === 'settings')}>
                  <MdOutlineSettings className="mIcon"/>
                  <p className="mTitle">Settings</p>
                </div>

                <div onClick={() => goTo('notifications')} className="mItem d-flex mBorder10" data-selected={(appContext.navItem === 'notifications')}>
                  <MdOutlineNotifications className="mIcon"/>
                  <p className="mTitle">Notifications</p>
                </div>

                <hr/>
    
              </>
              :""
            }
            
          </div>
        </div>
        <div onClick={() => appContext.setIsSideBarClosed(true)} className="Paper" style={{ display: (appContext.isSideBarClosed) ? "none" : "block" }}></div>
      </>
      
    );
  } else {
    return (
      <div className="SideBar" data-closed={appContext.isSideBarClosed}>
        <div
          style={{
            width: "100%",
            padding: "20px",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          className="text-center"
        >
          <img src={logo} style={{ width: "200px" }}/>
        </div>
      </div>
    )
  }
}