import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { MdDashboard, MdDarkMode, MdOutlineDarkMode, MdOutlineClose } from 'react-icons/md';
import { TbMenu, TbLogin } from 'react-icons/tb';

export default function MainLoader (props) {

  const appContext = useContext(AppContext);
  const [ show, setShow ] = useState(props.show);

  useEffect(() => {
    setShow(props.show);
  }, [ props.show ])

  return (
    <div className="MainLoader" style={{ display: (show) ? "block" : "none" }}>
      <div className="Loader"/>
    </div>
  );
}