import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, decodeAge, formatDateForInput, getInlineLoader } from "../Helpers";
import { MdAdd, MdDelete, MdOutlineCreateNewFolder, MdPhone } from "react-icons/md";

export default function View() {
  const appContext = useContext(AppContext);

  const currentDateTime = new Date();
  currentDateTime.setHours(currentDateTime.getHours() + 3); // Add 3 hours hacks

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ firstname, setFirstname ] = useState("");
  const [ lastname, setLastname ] = useState("");
  const [ age, setAge ] = useState("");
  const [ gender, setGender ] = useState("male");
  const [ birthDate, setBirthDate ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ visitDate, setVisitDate ] = useState(currentDateTime.toISOString().slice(0, 16));

  const testTemplate = {
    testTypeName: "",
    testTypeId: "",
    testResults: "",
    testRemarks: "",
  };

  const [ tests, setTests ] = useState([ testTemplate ]);
  const [ initialVisitId, setInitialVisitId ] = useState("");

  const [ isRevisitorLoading, setIsRevisitorLoading ] = useState(false);
  const [ revisitorData, setRevisitorData ] = useState(null);
  const [ revisitorSuggestions, setRevisitorSuggestions ] = useState(null);
  
  
  async function init () {
    //intialize this view here
    setReady(false);
    await appContext.getDiagnosticTestTypes();
    setReady(true);
  }

  function cleanTests() {
    setTests((oldTests) => {
      let newTests = [ ];

      for(let _test of oldTests) {
        if(
          _test.testTypeId && 
          _test.testTypeId.trim().length > 0 && 
          _test.testTypeName && 
          _test.testTypeName.trim().length > 0
        ) {
          newTests.push(_test);
        } 
      }

      return newTests;
    })
  }

  async function register() {
    if(!loading) {
      cleanTests();
      if(firstname.trim().length > 0 && lastname.trim().length > 0) {
        if(!isNaN(Number(age)) && Number(age) > 0) {
          if(gender.trim().length > 0) {
            if(birthDate.trim().length > 0) {
              if(visitDate.trim().length > 0) {
                if(phone.trim().length === 10) {
                  if(tests && tests.length > 0) {
                    //..
                    setLoading(true);
                    await callApi(
                      'register_patient.php', 
                      { 
                        firstname, 
                        lastname, 
                        phone, 
                        age, 
                        gender, 
                        birthDate, 
                        visitDate,
                        initialVisitId,
                        tests: JSON.stringify(tests), 
                      }).then((response) => {
                      if(response.status === 1) {
                        //successful
                        appContext.tellMessage("Successful");
                        appContext.getPatientsSummary();
                        resetFields();
                        setTests([testTemplate]);
                        appContext.navTo({
                          item: "patients",
                          subItem: "all",
                          extraItem: "today",
                        })
                      } else {
                        appContext.tellError(response.msg);
                      }
                    })
                    setLoading(false);
                    //..
                  } else {
                    appContext.tellError("Specify atleast one examination");
                  }
                  
                } else {
                  appContext.tellError("Invalid phone number, should be ten digits");
                }
                
              } else {
                appContext.tellError("Invalid visit date");
              }
            } else {
              appContext.tellError("Invalid birth date");
            }
            
          } else {
            appContext.tellError("Invalid gender");
          }
        } else {
          appContext.tellError("Invalid age");
        }

        
      } else {
        appContext.tellError("Invalid firstname or lastname");
      }
    } else {
      appContext.tellError("Please wait...");
    }
  }

  function loadRevisitorData() {
    if(revisitorData) {
      setFirstname(revisitorData.firstname || "");
      setLastname(revisitorData.lastname || "");
      setGender(revisitorData.gender || "")
      setPhone(revisitorData.phone || "");
      setBirthDate(formatDateForInput(revisitorData.birthDate) || "");
      setPhone(revisitorData.phone || "");
      setInitialVisitId(revisitorData.uid);
    }
  }

  function resetFields() {
    setFirstname("");
    setLastname("");
    setPhone("");
    setBirthDate("");
    setAge("");
    setPhone("");
  }

  async function getRevisitorData() {
    setIsRevisitorLoading(true);
    await callApi('get_revisitor_data.php', { initialVisitId: initialVisitId.trim() }).then((response) => {
      if(response.status === 1) {
        setRevisitorData(response.data);
      }
    })
    setIsRevisitorLoading(false);
  }

  async function getRevisitorSuggestions() {
    await callApi('get_revisitor_suggestions.php', { firstname, lastname, phone, uid: initialVisitId }).then((response) => {
      if(response.status === 1) {
        setRevisitorSuggestions(response.data);
      }
    })
  }

  function calculateAge() {
    
    const dob = new Date(birthDate);
    const today = new Date();

    let ageYears = today.getFullYear() - dob.getFullYear();
    let ageMonths = today.getMonth() - dob.getMonth();
    let ageDays = today.getDate() - dob.getDate();

    // Check if the current day is before the birth day
    if (ageDays < 0) {
        ageMonths--;

        // If the current month is before the birth month, adjust year difference
        if (ageMonths < 0) {
            ageYears--;
            ageMonths += 12;
        }

        // Calculate the remaining days in the month
        const daysInPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 0).getDate();
        ageDays += daysInPreviousMonth;
    }

    // Convert age to a decimal value representing years and months
    const calculatedAge = (ageYears + (ageMonths / 12) + (ageDays / 365)).toFixed(4);
    setAge((calculatedAge > 0) ? calculatedAge : 0);
    
  }


  function handleTestsChange(testIndex, testTypeIdValue) {
    setTests((oldTests) => {
      let newTests = [...oldTests];

      //get testTypeNameValue
      let testTypeNameValue = "";
      for(let _type of appContext.diagnosticTestTypes) {
        if(_type.uid === testTypeIdValue) {
          testTypeNameValue = _type.title;
        }
      }

      newTests[testIndex].testTypeId = testTypeIdValue;
      newTests[testIndex].testTypeName = testTypeNameValue;

      return newTests;
    })
  }

  function deleteTest(testIndex) {
    if(tests && tests.length > 1) {
      setTests((oldTests) => {
        let newTests = [ ...oldTests ];

        newTests.splice(testIndex, 1);
        return newTests;
      })
    } else {
      appContext.tellError("At least one examination is needed");
    }
  }

  function addTest() {
    setTests((oldTests) => {
      let newTests = [ ...oldTests, testTemplate ];

      return newTests;
    })
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(initialVisitId && initialVisitId.trim().length > 0) {
      getRevisitorData();
    }
  }, [ initialVisitId ])

  useEffect(() => {
    if(firstname || lastname ||  phone || initialVisitId) {
      getRevisitorSuggestions();
    }
  }, [ firstname, lastname, phone, initialVisitId ])

  useEffect(() => {
    if(revisitorData) {
      //load patient data here
      loadRevisitorData();
    } else {
      //clean fields
      resetFields();
    }
  }, [ revisitorData ])

  useEffect(() => {
    if(birthDate && birthDate.trim().length > 0) {
      calculateAge();
    }
  }, [ birthDate ])

  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])

  
  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Register Patient</h1>
          </div>

          <div className="col-md-12">
            <h6 className="font-bold text-muted">Only For Revisiting Patients</h6>

            <div className="row">
              
              <div className="col-md-6">
                <div className="form-group">
                  <label>Revisiting Patient ID<span className="text-muted">*</span></label>
                  <input
                    className="form-control"
                    value={initialVisitId}
                    onChange={(e) => {
                      setInitialVisitId(e.target.value)
                    }}
                  />
                </div>
                
              </div>

              <div className="col-md-6">
                <h6 style={{ fontSize: "14px" }}>Patient suggestions</h6>

                {
                  (revisitorSuggestions && revisitorSuggestions.length > 0) ?
                  revisitorSuggestions.map((item, i) => {
                    return (
                      <div 
                        style={{ 
                          width: "100%",
                          padding: "10px",
                          borderBottom: "1px solid var(--secondaryColor)",
                          cursor: "pointer",
                        }} 
                        className="" 
                        key={i}
                        onClick={() => {
                          setRevisitorData(item);
                        }}
                      >
                        <h5 style={{ fontSize: "16px" }} className="text-primary">{item.firstname} {item.lastname}</h5>
                        <h5 style={{ fontSize: "12px" }} className="font-bold text-muted">{item.uid}</h5>
                        <h5 style={{ fontSize: "12px" }} className="font-bold"><MdPhone size={16}/> {item.phone}</h5>
                      </div>
                    )
                  })
                  :
                  <div style={{ width: "100%" }}>
                    <h6 style={{ fontSize: "12px" }}>No suggestions</h6>
                  </div>
                }
                
              </div>
              
            </div>

          </div>

          <div className="col-md-12">
            <h6 className="font-bold text-muted">Patient's Details</h6>

            {
              (isRevisitorLoading) ?
              <div style={{ width: "100px" }} className="mSupportLoading">
                {getInlineLoader()}
              </div>
              :
              <>
                <div className="row">
              
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First name<span className="text-muted">*</span></label>
                      <input
                        className="form-control"
                        value={firstname}
                        onChange={(e) => {
                          setFirstname(e.target.value)
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Last name<span className="text-muted">*</span></label>
                      <input
                        className="form-control"
                        value={lastname}
                        onChange={(e) => {
                          setLastname(e.target.value)
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Date of Birth<span className="text-muted">*</span></label>
                      <input
                        className="form-control"
                        value={birthDate}
                        type="date"
                        onChange={(e) => {
                          setBirthDate(e.target.value)
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Age<span className="text-muted">*</span> ({decodeAge(age)})</label>
                      <input
                        className="form-control"
                        type="number"
                        value={age}
                        onChange={(e) => {
                          setAge(e.target.value)
                        }}
                        readOnly
                      />
                    </div>
                    
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Gender<span className="text-muted">*</span></label>
                      <select
                        className="form-control"
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value)
                        }}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Phone</label>
                      <input
                        className="form-control"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value)
                        }}
                        maxLength={10}
                        placeholder="07XXXXXXXX or 06XXXXXXXX"
                      />
                    </div>

                    <div className="form-group">
                      <label>Date of Visit<span className="text-muted">*</span></label>
                      <input
                        className="form-control"
                        value={visitDate}
                        type="datetime-local"
                        onChange={(e) => {
                          setVisitDate(e.target.value)
                        }}
                      />
                    </div>
                    
                  </div>
                  
                </div>
              </>
            }

          </div>

          <div className="col-md-12">
            <h6 className="font-bold text-muted">Examinations</h6>

            <div className="row">
              
              {
                (tests && tests.length > 0) ?
                tests.map((item, i) => {
                  return (
                    <div key={i} className="col-12">
                      <div
                        
                        style={{
                          width: "100%",
                          padding: "20px",
                        }}
                        className="mShadow3"
                      >
                        <div className="form-group">
                          <h5 className="font-light text-secondary" style={{ fontSize: "32px" }}>{item.testTypeName}</h5>
                          <label>Select below</label>
                          <select
                            className="form-control"
                            value={item.testTypeId}
                            onChange={(e) => {
                              handleTestsChange(i, e.target.value)
                            }}
                          >
                            <option value=""></option>
                            {
                              (appContext.diagnosticTestTypes && appContext.diagnosticTestTypes) ?
                              appContext.diagnosticTestTypes.map((_testType, _i) => {
                                return (
                                  <option 
                                    key={_i}
                                    value={_testType.uid}
                                  >
                                    {_testType.title}
                                  </option>
                                )
                              })
                              :""
                            }
                          </select>
                        </div>

                        <div className="text-end">
                          <button
                            className="btn"
                            onClick={() => {
                              deleteTest(i);
                            }}
                          >
                            <MdDelete className="text-danger" size={20}/>
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })
                :
                ""
              }

              <div className="col-md-12">
                <button
                  className="btn btn-outline-dark"
                  onClick={addTest}
                >
                  <MdAdd size={20}/> Add
                </button>
              </div>
              
            </div>

          </div>
          
          <div className="col-md-12">
            
            <div className="form-group text-end">
              <button
                className="btn btn-padded bg-primary mSupportLoading"
                onClick={() => {
                  if(!loading) {
                    appContext.activateDialog({
                      message: `This patient will be registered, continue?`,
                      onConfirm: register,
                    })
                  }
                }}
              >
                {(loading) ? getInlineLoader() : "Register Patient" }
              </button>
            </div>

          </div>
          

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
