import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, getInlineLoader } from "../Helpers";
import { MdOutlineCreateNewFolder } from "react-icons/md";

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ fullname, setFullname ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ role, setRole ] = useState("receptionist");
  const [ roleDescription, setRoleDescription ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ confirm, setConfirm ] = useState("");

  
  async function init () {
    //intialize this view here
    setReady(true);
  }

  async function create() {
    if(!loading) {
      if(fullname.trim().length > 0) {
        if(phone.trim().length === 10) {
          if(role.trim().length > 0) {
            if(password.trim().length > 0) {
              if(password === confirm) {
                setLoading(true);
                await callApi('create_new_staff.php', { fullname, phone, role, roleDescription, password }).then((response) => {
                  if(response.status === 1) {
                    //successful
                    appContext.tellMessage("Successful");
                    appContext.getAllStaff();
                    
                    setFullname("");
                    setRoleDescription("");
                    setPhone("");
                    setPassword("");
                    setConfirm("");
                  } else {
                    appContext.tellError(response.msg);
                  }
                })
                setLoading(false);
              } else {
                appContext.tellError("Please Confirm password correctly");
              }
            } else {
              appContext.tellError("Invalid password");
            }
          } else {
            appContext.tellError("Invalid Role");
          }
        } else {
          appContext.tellError("Invalid phone number");
        }
        
      } else {
        appContext.tellError("Invalid fullname");
      }
    } else {
      appContext.tellError("Please wait...");
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])

  
  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Create New Staff</h1>
          </div>

          
          <div className="col-md-6">
            <div className="form-group">
              <label>Fullname<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={fullname}
                onChange={(e) => {
                  setFullname(e.target.value)
                }}
              />
            </div>

            <div className="form-group">
              <label>Phone<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={phone}
                placeholder="07XXXXXXXX or 06XXXXXXXX"
                onChange={(e) => {
                  setPhone(e.target.value)
                }}
                maxLength={10}
              />
            </div>

            <div className="form-group">
              <label>Role<span className="text-muted">*</span></label>
              <select
                className="form-control"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value)
                }}
              >
                <option value="receptionist">Receptionist</option>
                <option value="sonographer">Sonographer</option>
                <option value="admin">Admin</option>
              </select>
            </div>

            <div className="form-group">
              <label>Role Description</label>
              <input
                className="form-control"
                value={roleDescription}
                placeholder="Eg specialist radiologist"
                onChange={(e) => {
                  setRoleDescription(e.target.value)
                }}
              />
            </div>

            
          </div>
          
          <div className="col-md-6">
            <div className="form-group">
              <label>Password<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                type="password"
              />
            </div>

            <div className="form-group">
              <label>Confirm<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={confirm}
                onChange={(e) => {
                  setConfirm(e.target.value)
                }}
                type="password"
              />
            </div>

          </div>

          <div className="col-md-12">
            <div className="form-group text-end">
              <button
                className="btn btn-padded bg-primary mSupportLoading"
                onClick={() => {
                  if(!loading) {
                    appContext.activateDialog({
                      message: `User "${fullname}" will be created, continue?`,
                      onConfirm: create,
                    })
                  }
                }}
              >
                {(loading) ? getInlineLoader() : "Create User" }
              </button>
            </div>
          </div>

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
